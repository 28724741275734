body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body, #root, .container {
  height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "ITC Officina Serif Std Book";
  src: local("ITC Officina Serif Std Book"),
    url(/static/media/OfficinaSerITCStd-Book.3e00da90.otf) format("opentype");
}

@font-face {
  font-family: "ITC Officina Serif Std Extra Bold";
  src: local("ITC Officina Serif Std Extra Bold"),
    url(/static/media/OfficinaSerITCStd-ExBold.e9dfe7b2.otf) format("opentype");
}

@font-face {
  font-family: "ITC Officina\AE   Serif Std Bold";
  src: local("ITC Officina\AE   Serif Std Bold"),
    url(/static/media/OfficinaSerITCStd-Bold.55575c5c.otf) format("opentype");
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content-card,
.head-card {
  padding: 0.5em;
  margin: 0.5em;
}

.content-card [class^="MuiCardContent"] {
  padding: 0 !important;
}

.tango-form [class^="MuiFormControl"] {
  margin: 1em;
  min-width: 28%;
}

.tango-form [class^="MuiFormControl"].double-size {
  width: 60% !important;
}

.tango-form [class^="MuiFormControlLabel"] {
  width: 28%;
}

.tango-form div.expansion-panel {
  width: 98%;
  margin: 1%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.recharts-legend-wrapper {
  bottom: -2em !important;
  padding-left: 1em;
}

.recharts-legend-item-text {
  font-size: 0.8em;
}

.recharts-wrapper text {
  font-size: 0.8em;
}

.groups-button {
  margin-left: 1em !important;
  vertical-align: top !important;
}

.groups-table .MTableToolbar-spacer-15 {
  flex: 0 1 !important;
}

.group-members-table-container td.MuiTableCell-body:nth-child(2) {
  padding: 0 !important;
}

.group-members-table-container td.MuiTableCell-body:nth-child(2) .MuiInputBase-formControl {
  margin: 0 !important;
}

.group-members-table-container .error {
  padding: 1em;
  font-size: 0.9em;
  color: red;
  text-align: center;
}

.tango-form .form-text {
  padding: 2%;
}

.recurring-donations-table-container {
  margin: 1%;
}

.MuiDialog-paperFullScreen{
  background-color: #e7e7e7 !important;
}

.MuiDialogContent-root {
  background-color: white !important;
}

